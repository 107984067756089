import AsyncStorage from '@react-native-async-storage/async-storage';
import logSentryException from '_/helpers/logSentryException';
import { Lang, Strategy } from '_/models/enums/';

import api from './base';

export interface GoogleOAuthResponse {
  idToken?: string;
  accessToken?: string;
}

export interface SignInRequestData {
  email: string;
  password: string;
}

export interface SignUpRequestData {
  name: string;
  email: string;
  password: string;
  lang?: Lang;
  cpf?: string | null;
  phone?: string | null;
  countryCode?: string | null;
}

interface RefreshTokenData {
  id: string;
  refreshToken: string;
}

const setStorageLocalDataLogin = async (data: any) => {
  try {
    const jsonValue = JSON.stringify(data);
    await AsyncStorage.setItem('dataLogin-storageLocal', jsonValue);
  } catch (e) {
    logSentryException({ error: e, message: 'setStorageLocalDataLogin' });
    console.log('setStorageLocalDataLogin' + e);
  }
};

export const getStorageLocalDataLogin = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem('dataLogin-storageLocal');
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    logSentryException({ error: e, message: 'getStorageLocalDataLogin' });
    console.log('getStorageLocalDataLogin' + e);
  }
};

class AuthApi {
  public login = async ({ email, password }: SignInRequestData) => {
    const { data } = await api.post('/authentication', {
      email,
      password,
      strategy: Strategy.LOCAL,
    });
    await setStorageLocalDataLogin(data);
    return data;
  };

  public createAccount = async (signupData: SignUpRequestData) => {
    const { data } = await api.post('/users', signupData);
    return data;
  };

  public loginWithGoogle = async ({ accessToken }: GoogleOAuthResponse) => {
    const { data } = await api.post('/authentication', {
      strategy: Strategy.GOOGLE,
      access_token: accessToken,
    });
    return data;
  };

  public resetPasswordSend = async (email: string) => {
    await api.post('/account', {
      email,
      createdOn: 'MYW3LCOME',
      action: 'resetPasswordSend',
    });
  };

  public sendVerificationEmail = async (email: string) => {
    await api.post('/account', {
      email,
      action: 'createUserConfirmation',
    });
  };

  public logout = async () => {
    await api.delete('/authentication');
  };

  public refreshToken = async ({ id, refreshToken }: RefreshTokenData) => {
    const { data } = await api.post('/refresh-tokens', { id, refreshToken });
    return data;
  };
}

export default new AuthApi();
