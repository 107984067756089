import { Text } from '_/components';
import Button from '_/components/Button';
import { getWebMaxWidth } from '_/components/ContainerWidthLimit';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Dimensions, Platform, TextInput } from 'react-native';
import Modal from 'react-native-modal';
import { useSelector, useDispatch } from 'react-redux';

import { removeMeetingRequest } from '_/store/modules/meeting/actions';

interface DeleteModalProps {
  visible?: boolean;
  hideModal: () => void;
}

export default function DeleteModal({ visible, hideModal }: DeleteModalProps) {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const [reason, setReason] = useState('');

  const { meeting } = useSelector((state: any) => state.meeting);

  function handleDeleteMeeting() {
    dispatch(removeMeetingRequest({ meetingId: meeting?.id, canceledMeetingVisitorNote: reason }));
  }

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>{translate('deleteModal.title')}</Text>
          <Text style={styles.description}>{translate('deleteModal.description')}</Text>
          <View style={styles.inputContainer}>
            <TextInput
              onChangeText={setReason}
              style={styles.modalInput}
              placeholder={translate('Reason')}
              multiline
              scrollEnabled={false}
              autoCorrect={false}
              value={reason}
            />
          </View>
        </View>
        <View style={styles.buttonsContainer}>
          <Button onPress={hideModal} buttonStyles={{ backgroundColor: themes.colors.danger }}>
            <Text uppercase={false} style={styles.btnText}>
              {translate('general.cancel')}
            </Text>
          </Button>
          <Button onPress={handleDeleteMeeting}>
            <Text uppercase={false} style={[styles.btnText, styles.btnTextDelete]}>
              {translate('general.delete')}
            </Text>
          </Button>
        </View>
      </View>
    </Modal>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  overlay: {
    alignItems: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    position: 'absolute',
    justifyContent: 'center',
    margin: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
  },
  container: {
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    borderRadius: 4,
    elevation: 2,
    justifyContent: 'center',
    overflow: 'hidden',
    shadowColor: themes.colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    ...(Platform.OS === 'web' ? { width: getWebMaxWidth() } : { width: width - 60 }),
  },
  content: {
    width: '100%',
    padding: 20,
    paddingHorizontal: 16,
    gap: 6,
  },
  inputContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    borderBottomColor: themes.colors.lightSecondary,
    marginBottom: 4,
    borderBottomWidth: 1,
  },
  modalInput: {
    flex: 1,
    fontSize: 16,
    paddingVertical: 8,
  },
  title: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
  },
  description: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.medium,
    fontSize: 14,
  },
  buttonsContainer: {
    alignItems: 'center',
    backgroundColor: themes.colors.lightPrimary,
    flexDirection: 'row',
    gap: 24,
    height: 70,
    justifyContent: 'flex-end',
    padding: 20,
    width: '100%',
  },
  btnText: {
    color: themes.colors.lightPrimary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
  },
  btnTextDelete: {
    color: themes.colors.white,
  },
});
