import AsyncStorage from '@react-native-async-storage/async-storage';
import { CompaniesModel, NotificationsModel } from '@w3lcome/types';
import logSentryException from '_/helpers/logSentryException';
import { notificationTokenApi, userApi } from '_/services/api';
import { setUserCurrentCompanyRequest } from '_/store/modules/company/actions';
import { handleOpenUrlRequest } from '_/store/modules/personal/actions';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import React, { createContext, useState, useCallback, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { useDispatch, useSelector } from 'react-redux';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

import { navigate } from '_/services/navigation';

import { useDeliveriesContext } from './DeliveriesProvider';
import { useVisitsContext } from './VisitsProvider';
import deliveriesApi from '../services/api/deliveries';
import visitsApi from '../services/api/visits';

interface PushNotificationData {
  checkNotificationsPermissions: () => Promise<void>;
  handleNotification: (notification: any) => Promise<void>;
  removeTokenFromDatabase: () => Promise<void>;
  verifyIfShouldShowModal: () => Promise<void>;
  skipNotificationModal: () => Promise<void>;
  loading: boolean;
  token?: NotificationsModel;
  visibleNotificationsCheckModal: boolean;
  visibleDeniedNotificationsModal: boolean;
  setVisibleNotificationsCheckModal: (value: boolean) => void;
  setVisibleDeniedNotificationsModal: (value: boolean) => void;
}

const PushNotificationContext = createContext<PushNotificationData>({} as PushNotificationData);

type PushNotificationType = {
  children: React.ReactNode;
};

export const PushNotificationProvider: React.FC<PushNotificationType> = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user);
  const { setSelectedVisit } = useVisitsContext();
  const { setSelectedDelivery } = useDeliveriesContext();
  const { t: translate } = useTranslation();

  const deviceIdRef = useRef<string>();
  const [token, setToken] = useState<NotificationsModel | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [visibleNotificationsCheckModal, setVisibleNotificationsCheckModal] =
    useState<boolean>(false);

  const { id: userId } = useSelector((state: any) => state.user);

  const [visibleDeniedNotificationsModal, setVisibleDeniedNotificationsModal] =
    useState<boolean>(false);

  useEffect(() => {
    async function loadDeviceId() {
      const storedDeviceId = await AsyncStorage.getItem('@w3lcome/deviceId');
      if (storedDeviceId) {
        deviceIdRef.current = storedDeviceId;
        return;
      }

      const deviceId = uuidv4();
      await AsyncStorage.setItem('@w3lcome/deviceId', deviceId);

      deviceIdRef.current = deviceId;
    }

    loadDeviceId();
  }, []);

  async function verifyIfShouldShowModal() {
    setLoading(true);
    const storagedStatus = await AsyncStorage.getItem('@w3lcome/notifications');
    const { status: existingStatus } = await Notifications.getPermissionsAsync();

    if (existingStatus === 'undetermined') {
      await Notifications.requestPermissionsAsync();
    } else if (existingStatus === 'denied' && storagedStatus !== 'skipped') {
      setVisibleDeniedNotificationsModal(true);
      setLoading(false);
      return;
    }

    const result = await notificationTokenApi.getAllNotificationTokens({
      userId,
      deviceId: deviceIdRef.current,
    });

    if (result?.data?.[0]?.token) {
      setToken(result.data[0]);
      await AsyncStorage.setItem('@w3lcome/notifications', 'enabled');
      setLoading(false);
      return;
    }

    if (storagedStatus !== 'skipped') {
      setVisibleNotificationsCheckModal(true);
    }

    setLoading(false);
  }

  async function removeTokenFromDatabase() {
    setLoading(true);
    try {
      if (token?.id) {
        await notificationTokenApi.deleteNotificationToken(token.id);
        setToken(undefined);
        await AsyncStorage.setItem('@w3lcome/notifications', 'denied');
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.code !== 404) {
        showMessage({
          message: translate('notificationsCheckModal.serviceNotWorking'),
          type: 'danger',
        });
      }
    }
    setLoading(false);
  }

  async function skipNotificationModal() {
    setLoading(true);

    try {
      await AsyncStorage.setItem('@w3lcome/notifications', 'skipped');

      setVisibleNotificationsCheckModal(false);
    } catch (error) {
      console.tron.log(error);
    }

    setLoading(false);
  }

  async function checkNotificationsPermissions() {
    setLoading(true);

    try {
      let { status: existingStatus } = await Notifications.getPermissionsAsync();

      if (existingStatus === 'undetermined') {
        ({ status: existingStatus } = await Notifications.requestPermissionsAsync());
      }

      if (existingStatus === 'granted') {
        if (Platform.OS === 'android') {
          Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
          });
        }

        const newToken = (
          await Notifications.getExpoPushTokenAsync({
            projectId: Constants.expoConfig?.extra?.eas?.projectId,
          })
        ).data;

        await setTokenOnDatabase({ deviceId: deviceIdRef.current, token: newToken });

        setVisibleNotificationsCheckModal(false);
      } else {
        // TODO: Mostrar mensagem informando que a permissão foi negada
        setVisibleDeniedNotificationsModal(true);
      }
    } catch (error) {
      logSentryException({
        error,
        file: 'usePushNotifications.js',
        message: 'Error getting permissions for notifications',
      });
      showMessage({
        message: translate('notificationsCheckModal.serviceNotWorking'),
        type: 'danger',
      });
    }

    setLoading(false);
  }

  async function setTokenOnDatabase({ deviceId, token }: { deviceId?: string; token?: string }) {
    if (!token || !deviceId) {
      return;
    }

    setLoading(true);
    try {
      const data = await notificationTokenApi.createNotificationToken({
        deviceId,
        token,
        userId: user?.id,
        type: 'EXPO',
      });

      await AsyncStorage.setItem('@w3lcome/notifications', 'enabled');
      setToken(data);
    } catch (error) {
      console.tron.log(error);
      showMessage({
        message: translate('notificationsCheckModal.serviceNotWorking'),
        type: 'danger',
      });
    }
    setLoading(false);
  }

  async function checkCompanyId(companyId: string) {
    const { currentCompanyId } = await userApi.getUser(user.id);
    const isSameCompanyId = companyId === currentCompanyId;

    if (!isSameCompanyId) {
      const hasCompanyId = user.companies.some((e: CompaniesModel) => e.id === companyId);

      if (hasCompanyId) {
        await userApi.updateUser({ id: user.id, currentCompanyId: companyId });
        const userCompany = user.companies.find(
          (company: CompaniesModel) => company.id === companyId
        );
        dispatch(setUserCurrentCompanyRequest(userCompany, false));
      }

      return hasCompanyId;
    }

    return isSameCompanyId;
  }

  const handleNotification = useCallback(
    async (notification: Notifications.NotificationResponse) => {
      const {
        notification: {
          request: { content },
        },
      } = notification;

      try {
        const { type, visitId, deliveryId, meetingId, isLink, companyId } = content.data as Record<
          string,
          any
        >;

        const isUserCurrentCompanyId = await checkCompanyId(companyId);

        if (isUserCurrentCompanyId) {
          if (visitId && type === 'visit') {
            const visitData = await visitsApi.getVisit(visitId);

            setSelectedVisit(visitData);
            navigate('Visitor');
          }
          if (deliveryId && type === 'delivery') {
            const deliveryData = await deliveriesApi.getDelivery(deliveryId);

            setSelectedDelivery(deliveryData);
            navigate('Delivery');
          }
          if (meetingId && type === 'meeting') {
            dispatch(handleOpenUrlRequest(meetingId, isLink));
          }
        }
      } catch (error) {
        logSentryException({
          error,
          file: 'usePushNotifications.js',
          message: 'Error at handleNotification function',
        });
      }
    },
    [dispatch]
  );

  return (
    <PushNotificationContext.Provider
      value={{
        checkNotificationsPermissions,
        removeTokenFromDatabase,
        skipNotificationModal,
        verifyIfShouldShowModal,
        handleNotification,
        loading,
        token,
        visibleNotificationsCheckModal,
        setVisibleNotificationsCheckModal,
        visibleDeniedNotificationsModal,
        setVisibleDeniedNotificationsModal,
      }}
    >
      {children}
    </PushNotificationContext.Provider>
  );
};

export function usePushNotificationContext() {
  const context = useContext(PushNotificationContext);

  if (!context) {
    throw new Error('usePushNotification must be used within a PushNotificationProvider');
  }

  return context;
}
