import { AppRoute } from '_/routes/types';
import { showMessage } from 'react-native-flash-message';
import { takeLatest, all, put, call } from 'redux-saga/effects';

import { translate } from '../../../locales';
import meetingLinksApi from '../../../services/api/meeting-links';
import meetingsApi from '../../../services/api/meetings';
import { navigate } from '../../../services/navigation';
import { setLoading } from '../loading/actions';
import {
  meetingFailure,
  createLinkSuccess,
  createLinkFailure,
  createMeetingSuccess,
  updateMeetingSuccess,
  removeMeetingSuccess,
  getMeetingDetailsSuccess,
  createLinkRequest,
} from './actions';

export function* getMeetingDetails({ payload }) {
  try {
    yield put(setLoading());
    const { meeting } = payload;
    yield put(setLoading());
    yield put(getMeetingDetailsSuccess(meeting));
    navigate(AppRoute.MEETING);
  } catch (error) {
    yield put(setLoading());
    console.tron.log('File: meeting/sagas.js', error);
    showMessage({
      message: translate('meetingSaga.getMeetingDetailsError'),
      type: 'danger',
    });
    yield put(meetingFailure());
  }
}

export function* createMeeting({ payload }) {
  try {
    yield put(setLoading());
    const { meeting } = payload;

    const newMeeting = yield call(meetingsApi.createNewMeeting, meeting);

    const { limit } = meeting;
    if (limit) {
      try {
        yield put(createLinkRequest(limit, newMeeting.id, newMeeting.companyId));
      } catch (error) {
        yield put(createLinkFailure());
      }
    }

    yield put(setLoading());

    showMessage({
      message: translate('meetingSaga.createMeetingSuccess'),
      type: 'success',
    });
    yield put(createMeetingSuccess(newMeeting));
    navigate(AppRoute.MEETING);
  } catch (error) {
    console.tron.log('File: meeting/sagas.js', error);
    yield put(setLoading());

    showMessage({
      message: translate('meetingSaga.createMeetingError'),
      type: 'danger',
    });
    yield put(meetingFailure());
  }
}

export function* updateMeeting({ payload }) {
  try {
    yield put(setLoading());
    const { meeting } = payload;

    const newMeeting = yield call(meetingsApi.patchCurrentMeeting, meeting);

    yield put(setLoading());
    yield put(updateMeetingSuccess(newMeeting));

    showMessage({
      message: translate('meetingSaga.updateMeetingSuccess'),
      type: 'success',
    });
    navigate(AppRoute.MEETING);
  } catch (error) {
    console.tron.log('File: meeting/sagas.js', error);
    yield put(setLoading());
    yield put(meetingFailure());

    showMessage({
      message: translate('meetingSaga.updateMeetingError'),
      type: 'danger',
    });
  }
}

export function* removeMeeting({ payload }) {
  try {
    yield put(setLoading());
    const { meetingId, canceledMeetingVisitorNote } = payload;

    const deletedMeeting = yield meetingsApi.removeMeeting(meetingId, {
      params: { canceledMeetingVisitorNote },
    });

    yield put(setLoading());
    yield put(removeMeetingSuccess(deletedMeeting));

    showMessage({
      message: translate('meetingSaga.updateMeetingSuccess'),
      type: 'success',
    });

    navigate(AppRoute.MEETINGS);
  } catch (error) {
    console.tron.log('File: meeting/sagas.js', error);
    yield put(setLoading());
    yield put(meetingFailure());

    showMessage({
      message: translate('meetingSaga.removeMeetingError'),
      type: 'danger',
    });
  }
}

export function* createLink({ payload }) {
  try {
    yield put(setLoading());
    const { limit, meetingId, companyId } = payload;

    const res = yield call(meetingLinksApi.createMeetingLink, {
      limit,
      meetingId,
      companyId,
    });

    const { link } = res;
    yield put(setLoading());

    showMessage({
      message: translate('meetingSaga.createLinkSuccess'),
      type: 'success',
    });
    yield put(createLinkSuccess(link));
  } catch (error) {
    yield put(setLoading());

    showMessage({
      message: translate('meetingSaga.createLinkError'),
      type: 'danger',
    });
    yield put(createLinkFailure());
    console.tron.log('File: meeting/sagas.js', error);
  }
}

export default all([
  takeLatest('@meeting/GET_MEETING_DETAILS_REQUEST', getMeetingDetails),
  takeLatest('@meeting/CREATE_MEETING_REQUEST', createMeeting),
  takeLatest('@meeting/UPDATE_MEETING_REQUEST', updateMeeting),
  takeLatest('@meeting/REMOVE_MEETING_REQUEST', removeMeeting),
  takeLatest('@meeting/CREATE_LINK_REQUEST', createLink),
]);
