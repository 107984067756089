import { Feather } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { UserCompanyRole } from '@w3lcome/types';
import React from 'react';
import { Image } from 'react-native';

import { AppRoute } from './types';
import themes from '../constants/themes';
import {
  Home,
  Intro,
  Signin,
  Signup,
  QRCode,
  Profile,
  Visitor,
  Delivery,
  Meeting,
  Meetings,
  NFCReader,
  EntryReader,
  PlanDetails,
  PlanExpired,
  EditMeeting,
  EditProfile,
  PasswordSent,
  QRCodeReader,
  DeleteAccount,
  ForgotPassword,
  PersonalInvite,
  PersonalInvites,
  PersonalMeeting,
  EmailConfirmation,
  CompanyConfirmation,
  PersonalMeetingError,
  PersonalMeetingNotFound,
} from '../screens';
import Notification from '../screens/Notification';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const SBT_COMPANY_ID = '9501fedf-3f2f-4e9c-b20e-d8d39097f919';

type TabIconProps = { color: string };

function Auth() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={AppRoute.SIGNIN}>
      <Stack.Screen name={AppRoute.SIGNIN} component={Signin} />
      <Stack.Screen name={AppRoute.SIGNOUT} component={Signup} />
      <Stack.Screen name={AppRoute.FORGOT_PASSWORD} component={ForgotPassword} />
      <Stack.Screen name={AppRoute.PASSWORD_SENT} component={PasswordSent} />
      <Stack.Screen name={AppRoute.EMAIL_CONFIRMATION_NAV} component={EmailConfirmation} />
      <Stack.Screen name={AppRoute.COMPANY_CONFIRMATION_NAV} component={CompanyConfirmation} />
    </Stack.Navigator>
  );
}

function HomeStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={AppRoute.HOME}>
      <Stack.Screen name={AppRoute.HOME} component={Home} />
      <Stack.Screen name={AppRoute.VISITOR} component={Visitor} />
      <Stack.Screen name={AppRoute.DELIVERY} component={Delivery} />
      <Stack.Screen name={AppRoute.SIGNIN} component={Signin} />
      <Stack.Screen name={AppRoute.NOTIFICATION} component={Notification} />
    </Stack.Navigator>
  );
}

function MeetingStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={AppRoute.MEETINGS}>
      <Stack.Screen name={AppRoute.MEETINGS} component={Meetings} />
      <Stack.Screen name={AppRoute.MEETING} component={Meeting} />
      <Stack.Screen name={AppRoute.VISITOR} component={Visitor} />
      <Stack.Screen name={AppRoute.EDIT_MEETING} component={EditMeeting} />
      <Stack.Screen name={AppRoute.NOTIFICATION} component={Notification} />
    </Stack.Navigator>
  );
}

function QRCodeStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={AppRoute.QRCODE} component={QRCode} />
      <Stack.Screen name={AppRoute.QRCODE_RENDER} component={QRCodeReader} />
      <Stack.Screen name={AppRoute.NFC_READER} component={NFCReader} />
      <Stack.Screen name={AppRoute.ENTRY_READER} component={EntryReader} />
      <Stack.Screen name={AppRoute.NOTIFICATION} component={Notification} />
    </Stack.Navigator>
  );
}

function ProfileStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={AppRoute.PROFILE} component={Profile} />
      <Stack.Screen name={AppRoute.EDIT_PROFILE} component={EditProfile} />
      <Stack.Screen name={AppRoute.PLAN_DETAILS} component={PlanDetails} />
      <Stack.Screen name={AppRoute.DELETE_ACCOUNT} component={DeleteAccount} />
      <Stack.Screen name={AppRoute.MEETING} component={Meeting} />
      <Stack.Screen name={AppRoute.VISITOR} component={Visitor} />
      <Stack.Screen name={AppRoute.DELIVERY} component={Delivery} />
      <Stack.Screen name={AppRoute.PERSONAL_INVITE} component={PersonalInvite} />
      <Stack.Screen name={AppRoute.PERSONAL_MEETING} component={PersonalMeeting} />
      <Stack.Screen name={AppRoute.NOTIFICATION} component={Notification} />
    </Stack.Navigator>
  );
}

function App({
  role,
  showQRCodePagefirst,
  companyId,
}: {
  role: UserCompanyRole;
  showQRCodePagefirst: boolean;
  companyId?: string;
}) {
  const isSbtFrontdesk = role === UserCompanyRole.FRONTDESK && companyId === SBT_COMPANY_ID;

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: '#111',
        tabBarInactiveTintColor: '#444',
        tabBarInactiveBackgroundColor: '#fff',
        tabBarActiveBackgroundColor: 'rgba(44, 54, 84, 0.06)',
        tabBarShowLabel: false,
        tabBarHideOnKeyboard: true,
        headerShown: false,
      }}
      initialRouteName={showQRCodePagefirst ? AppRoute.QRCODE : AppRoute.HOME}
    >
      <Tab.Screen
        name={AppRoute.HOME}
        component={HomeStack}
        options={{
          tabBarIcon: ({ color }: TabIconProps) => <Feather name="home" size={20} color={color} />,
        }}
      />
      {role !== UserCompanyRole.EMPLOYEE && !isSbtFrontdesk && (
        <Tab.Screen
          name={AppRoute.MEETINGS}
          component={MeetingStack}
          options={{
            tabBarIcon: ({ color }: TabIconProps) => (
              <Image
                resizeMode="contain"
                source={themes.images.calendarIcon}
                style={{ width: 20, height: 20, tintColor: color }}
              />
            ),
          }}
        />
      )}
      <Tab.Screen
        name={AppRoute.QRCODE}
        component={QRCodeStack}
        options={{
          tabBarIcon: ({ color }: TabIconProps) => (
            <Image
              resizeMode="contain"
              source={themes.images.qrcodeIcon}
              style={{ width: 20, height: 20, tintColor: color }}
            />
          ),
        }}
      />
      <Tab.Screen
        name={AppRoute.PROFILE}
        component={ProfileStack}
        options={{
          tabBarIcon: ({ color }: TabIconProps) => <Feather name="user" size={20} color={color} />,
        }}
      />
    </Tab.Navigator>
  );
}

function Expired() {
  return (
    <Stack.Navigator
      initialRouteName={AppRoute.PLAN_EXPIRED}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name={AppRoute.PLAN_EXPIRED} component={PlanExpired} />
      <Stack.Screen name={AppRoute.PROFILE} component={ProfileStack} />
    </Stack.Navigator>
  );
}

function QRCodeAndProfileOnly() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={AppRoute.QRCODE} component={QRCodeStack} />
      <Stack.Screen name={AppRoute.PROFILE} component={ProfileStack} />
    </Stack.Navigator>
  );
}

function PersonalInvitesStack() {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={AppRoute.PERSONAL_INVITES}
    >
      <Stack.Screen name={AppRoute.PERSONAL_INVITES} component={PersonalInvites} />
      <Stack.Screen name={AppRoute.PERSONAL_INVITE} component={PersonalInvite} />
      <Stack.Screen name={AppRoute.PERSONAL_MEETING} component={PersonalMeeting} />
      <Stack.Screen name={AppRoute.PERSONAL_MEETING_ERROR} component={PersonalMeetingError} />
      <Stack.Screen
        name={AppRoute.PERSONAL_MEETING_NOT_FOUND}
        component={PersonalMeetingNotFound}
      />
    </Stack.Navigator>
  );
}

function Personal() {
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: '#111',
        tabBarInactiveTintColor: '#444',
        tabBarInactiveBackgroundColor: '#fff',
        tabBarActiveBackgroundColor: 'rgba(44, 54, 84, 0.06)',
        tabBarShowLabel: false,
        tabBarHideOnKeyboard: true,
        headerShown: false,
      }}
      initialRouteName={AppRoute.PERSONAL_INVITES}
    >
      <Tab.Screen
        name={AppRoute.PERSONAL_INVITES}
        component={PersonalInvitesStack}
        options={{
          tabBarIcon: ({ color }: TabIconProps) => (
            <Image
              resizeMode="contain"
              source={themes.images.calendarIcon}
              style={{ width: 20, height: 20, tintColor: color }}
            />
          ),
        }}
      />
      <Tab.Screen
        name={AppRoute.PROFILE}
        component={ProfileStack}
        options={{
          tabBarIcon: ({ color }: TabIconProps) => <Feather name="user" size={20} color={color} />,
        }}
      />
    </Tab.Navigator>
  );
}

interface RoutesProps {
  auth: any;
  onlyDynamicQRCode: boolean;
  showQRCodePagefirst: boolean;
  expired?: boolean;
}

export default function Routes({
  auth,
  onlyDynamicQRCode = false,
  showQRCodePagefirst = false,
  expired = false,
}: RoutesProps) {
  if (auth.signed) {
    if (expired) {
      return <Expired />;
    }

    if (auth.personal) {
      return <Personal />;
    }
    if (onlyDynamicQRCode) {
      return <QRCodeAndProfileOnly />;
    }
    return (
      <App role={auth.role} showQRCodePagefirst={showQRCodePagefirst} companyId={auth.companyId} />
    );
  }
  if (auth.isFirstOpen) {
    return <Intro />;
  }

  return <Auth />;
}
