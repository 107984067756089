import AsyncStorage from '@react-native-async-storage/async-storage';
import { VisitAuthorizationStatusType, VisitAuthorizationVia, VisitsModel } from '@w3lcome/types';
import logSentryException from '_/helpers/logSentryException';
import { visitsApi } from '_/services/api';
import { endOfDay, startOfDay } from 'date-fns';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { useSelector } from 'react-redux';

import { useUser } from './UserProvider';
import { useActiveAppState } from './useActiveAppState';
import { DataType } from '../interfaces/DataType';
import { FormatedVisitsModel } from '../interfaces/FormatedVisitsModel';

interface GetVisitsData {
  startDate?: Date;
  endDate?: Date;
}

interface VisitsData {
  visits: FormatedVisitsModel[];
  selectedVisit: VisitsModel | undefined;
  setSelectedVisit(data: VisitsModel | undefined): void;
  getVisists(value: GetVisitsData): void;
  updateVisit(visitId: string, data: Partial<VisitsModel>): Promise<void>;
  allowDenyVisit(
    visitId: string,
    status: VisitAuthorizationStatusType,
    visitorNoteDenied?: string | null
  ): Promise<void>;
  expectedVisits: FormatedVisitsModel[];
  visitsToday: FormatedVisitsModel[];
  loading: boolean;
  cancelVisit(visitId: string): void;
  getCheckinAndExpectedVisits(showLoading?: boolean): void;
  delayVisit(
    visit: VisitsModel,
    minutesToDelayVisit: number,
    delayVisitReason?: string
  ): Promise<void>;
}

const VisitsContext = createContext<VisitsData>({} as VisitsData);

type VisitsType = {
  children: React.ReactNode;
};

export const VisitsProvider: React.FC<VisitsType> = ({ children }) => {
  const { t: translate } = useTranslation();
  const [selectedVisit, setSelectedVisit] = useState<VisitsModel>();
  const [visits, setVisits] = useState<FormatedVisitsModel[]>([]);
  const [visitsToday, setVisitsToday] = useState<FormatedVisitsModel[]>([]);
  const [expectedVisits, setExpectedVisits] = useState<FormatedVisitsModel[]>([]);
  const { id: hostId } = useSelector((state: any) => state.host);
  const companyId = useSelector((state: any) => state.company.id);
  const [loading, setLoading] = useState(false);
  const { isActive } = useActiveAppState();

  const { feathersApp } = useUser();

  const getCheckinVisits = useCallback(async () => {
    const startOfDayDate = startOfDay(new Date());

    try {
      const appTimeInBackground = await AsyncStorage.getItem('@w3lcome/appTimeInBackground');

      const paramsCheckinVisits = {
        'checkinAt[$gte]': startOfDayDate,
        '$sort[checkinAt]': -1,
        checkoutAt: '<null>' as any,
        denyRoles: true,
        ...(appTimeInBackground && { '$or[0][createdAt][$gte]': new Date(appTimeInBackground) }),
        ...(appTimeInBackground && { '$or[1][updatedAt][$gte]': new Date(appTimeInBackground) }),
      };

      const { data: checkinVisitsData } = await visitsApi.getVisits(paramsCheckinVisits);

      const verifiedCheckinVisitsResult = checkinVisitsData.map((visit: VisitsModel) => {
        return {
          ...visit,
          date: visit.expectedAt || visit.checkinAt || visit.createdAt,
          type: DataType.visit,
        };
      });

      if (appTimeInBackground) {
        const newVisitsToday = [
          ...visitsToday.filter((v1) => !verifiedCheckinVisitsResult.find((v2) => v2.id === v1.id)),
          ...verifiedCheckinVisitsResult,
        ];

        setVisitsToday(
          newVisitsToday.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        );

        await AsyncStorage.removeItem('@w3lcome/appTimeInBackground');

        return;
      }

      setVisitsToday(verifiedCheckinVisitsResult);
    } catch (error) {
      logSentryException({
        error,
        file: 'VisitsProvider.tsx',
        message: 'Error at getCheckiVisits function',
      });
    }
  }, [visitsToday]);

  const loadCheckinVisit = useCallback(
    (data: VisitsModel) => {
      if (companyId === data?.companyId && hostId === data.hostId) {
        const visit = {
          ...data,
          date: data.expectedAt || data.checkinAt || data.createdAt,
          type: DataType.visit,
        };

        const visitExists = visitsToday.some((v) => v.id === visit.id);

        if (visitExists) {
          updateVisitLocally(visit);
          return;
        }

        if (visit.checkinAt) {
          const visitExpectedExists = expectedVisits.some((v) => v.id === visit.id);

          if (visitExpectedExists) {
            setExpectedVisits(expectedVisits.filter((v) => v.id !== visit.id));
          }

          addVisiCheckintLocally(visit);
        }
      }
    },
    [companyId, hostId, visitsToday, expectedVisits]
  );

  const getExpectedVisits = useCallback(async () => {
    const startOfDayDate = startOfDay(new Date());

    try {
      const appTimeInBackground = await AsyncStorage.getItem('@w3lcome/appTimeInBackground');

      const paramsExpectedVisits = {
        '[expectedAt][$gte]': startOfDayDate,
        checkinAt: '<null>' as any,
        '$sort[expectedAt]': -1,
        denyRoles: true,
        ...(appTimeInBackground && { '$or[0][createdAt][$gte]': new Date(appTimeInBackground) }),
        ...(appTimeInBackground && { '$or[1][updatedAt][$gte]': new Date(appTimeInBackground) }),
      };

      const { data: expectedVisitsData } = await visitsApi.getVisits(paramsExpectedVisits);

      const verifiedExpectedVisitsResult = expectedVisitsData.map((visit: VisitsModel) => {
        return {
          ...visit,
          date: visit.expectedAt || visit.checkinAt || visit.createdAt,
          type: DataType.visit,
        };
      });

      if (appTimeInBackground) {
        const newExpectedVisits = [
          ...expectedVisits.filter(
            (v1) => !verifiedExpectedVisitsResult.find((v2) => v2.id === v1.id)
          ),
          ...verifiedExpectedVisitsResult,
        ];

        setExpectedVisits(
          newExpectedVisits.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        );

        await AsyncStorage.removeItem('@w3lcome/appTimeInBackground');

        return;
      }

      setExpectedVisits(verifiedExpectedVisitsResult);
    } catch (error) {
      logSentryException({
        error,
        file: 'VisitsProvider.tsx',
        message: 'Error at getExpectedVisits function',
      });
    }
  }, [expectedVisits]);

  const loadExpectedVisit = useCallback(
    (data: VisitsModel) => {
      if (companyId === data?.companyId && hostId === data.hostId) {
        const visit = {
          ...data,
          date: data.expectedAt || data.checkinAt || data.createdAt,
          type: DataType.visit,
        };

        const visitExists = expectedVisits.some((v) => v.id === visit.id);

        if (visitExists) {
          updateVisitLocally(visit);
          return;
        }

        addVisitExpectedLocally(visit);
      }
    },
    [companyId, hostId, expectedVisits]
  );

  useEffect(() => {
    if (isActive) {
      getCheckinVisits();
      getExpectedVisits();
    }
  }, [isActive]);

  useEffect(() => {
    feathersApp?.service('visits').on('created', loadCheckinVisit);
    feathersApp?.service('ipad/visits').on('created', loadCheckinVisit);
    feathersApp?.service('visits').on('patched', loadCheckinVisit);
    feathersApp?.service('ipad/visits').on('patched', loadCheckinVisit);
    feathersApp?.service('appointments').on('created', loadExpectedVisit);

    return () => {
      feathersApp?.service('visits').off('created', loadCheckinVisit);
      feathersApp?.service('ipad/visits').off('created', loadCheckinVisit);
      feathersApp?.service('visits').off('patched', loadCheckinVisit);
      feathersApp?.service('ipad/visits').off('patched', loadCheckinVisit);
      feathersApp?.service('appointments').off('created', loadExpectedVisit);
    };
  }, [feathersApp, hostId, companyId, loadExpectedVisit, loadCheckinVisit]);

  async function getCheckinAndExpectedVisits(showLoading = false) {
    try {
      showLoading && setLoading(showLoading);

      await Promise.all([getCheckinVisits(), getExpectedVisits()]);

      showLoading && setLoading(false);
    } catch (error) {
      showLoading && setLoading(false);
      logSentryException({
        error,
        file: 'VisitsProvider.tsx',
        message: 'Error at getCheckinAndExpectedVisits function',
      });
    }
  }

  async function getVisists({ startDate, endDate }: GetVisitsData) {
    const dateStartOfDay = startOfDay(startDate as Date);
    const dateEndOfDay = endOfDay(endDate as Date);
    setLoading(true);
    const params =
      startDate && endDate
        ? {
            companyId,
            hostId,
            'createdAt[$gte]': dateStartOfDay,
            'createdAt[$lte]': dateEndOfDay,
            '$sort[createdAt]': -1,
          }
        : {
            companyId,
            hostId,
            '$sort[createdAt]': -1,
          };

    try {
      const { data } = await visitsApi.getVisits(params);

      const visitsArr: FormatedVisitsModel[] = data.map((visit: VisitsModel) => {
        return {
          ...visit,
          type: DataType.visit,
          date: visit.expectedAt || visit.checkinAt || visit.createdAt,
        };
      });
      setVisits(visitsArr);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      logSentryException({
        error,
        file: 'VisitsProvider.tsx',
        message: 'Error at getVisits function',
      });
    }
  }

  function addVisiCheckintLocally(visit: FormatedVisitsModel) {
    setVisitsToday((prevVisits) => [visit, ...prevVisits]);
  }

  function addVisitExpectedLocally(visit: FormatedVisitsModel) {
    setExpectedVisits((prevVisits) => [visit, ...prevVisits]);
  }

  function updateVisitLocally(visit: VisitsModel) {
    setVisits((prevVisits) => prevVisits.map((v) => (v.id === visit.id ? { ...v, ...visit } : v)));

    setVisitsToday((prevVisits) =>
      prevVisits.map((v) => (v.id === visit.id ? { ...v, ...visit } : v))
    );

    setExpectedVisits((prevVisits) =>
      prevVisits.map((v) => (v.id === visit.id ? { ...v, ...visit } : v))
    );

    if (selectedVisit?.id === visit.id) {
      setSelectedVisit((prevVisit) => (prevVisit ? { ...prevVisit, ...visit } : undefined));
    }
  }

  async function updateVisit(visitId: string, data: Partial<VisitsModel>) {
    const updatedVisit = await visitsApi.update(visitId, data);
    updateVisitLocally(updatedVisit);
  }

  async function cancelVisit(visitId: string) {
    try {
      const visit = await visitsApi.cancel(visitId, btoa(companyId));
      updateVisitLocally(visit);
    } catch (error) {
      showMessage({
        message: translate('cancelVisitError'),
        type: 'danger',
      });
    }
  }

  async function allowDenyVisit(
    visitId: string,
    status: VisitAuthorizationStatusType,
    visitorNoteDenied?: string | null
  ) {
    const updatedVisit = await visitsApi.authorizeOrDenyVisit({
      visitId,
      authorizationStatus: status,
      authorizationVia: VisitAuthorizationVia.APP,
      visitorNoteDenied,
    });
    updateVisitLocally(updatedVisit);
  }

  async function delayVisit(
    visit: VisitsModel,
    minutesToDelayVisit: number,
    delayVisitReason?: string
  ) {
    const updatedVisit = await visitsApi.delayVisit(visit, minutesToDelayVisit, delayVisitReason);
    updateVisitLocally(updatedVisit);
  }

  return (
    <VisitsContext.Provider
      value={{
        visits,
        selectedVisit,
        setSelectedVisit,
        getVisists,
        updateVisit,
        allowDenyVisit,
        expectedVisits,
        visitsToday,
        loading,
        cancelVisit,
        getCheckinAndExpectedVisits,
        delayVisit,
      }}
    >
      {children}
    </VisitsContext.Provider>
  );
};

export function useVisitsContext() {
  const context = useContext(VisitsContext);

  if (!context) {
    throw new Error('useVisits must be used within a VisitsProvider');
  }

  return context;
}
