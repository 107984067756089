import Feather from '@expo/vector-icons/Feather';
import { CompaniesModel } from '@w3lcome/types';
import { colors, fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { setPersonalProfile } from '_/store/modules/auth/actions';
import { setUserCurrentCompanyRequest } from '_/store/modules/company/actions';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  ViewStyle,
  StyleProp,
} from 'react-native';
import Modal from 'react-native-modal';
import { useSelector, useDispatch } from 'react-redux';

import { SwitchCard } from './SwitchCard';
import Divider from '../../Divider';
import Text from '../../Text';
import { transparentize } from 'polished';

interface SwitchCompanyProps {
  content: JSX.Element;
  buttonStyles?: StyleProp<ViewStyle>;
}

interface CompaniesModelWithChildren extends CompaniesModel {
  parentCompanyId: string | null;
  children: CompaniesModelWithChildren[];
}

export default function SwitchCompany({ content, buttonStyles }: SwitchCompanyProps) {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const { loading } = useSelector((state: any) => state.loading);
  const [visible, setVisible] = useState(false);
  const { t: translate } = useTranslation();

  const handleSwitchProfile = useCallback((data: CompaniesModel, type: 'company' | 'user') => {
    if (type === 'company') {
      dispatch(setUserCurrentCompanyRequest(data, true));
      dispatch(setPersonalProfile(false));

      setVisible(false);
      return;
    }

    if (type === 'user') {
      dispatch(setPersonalProfile(true));
    }
  }, []);

  const getCompaniesWithChildren = (companies: CompaniesModelWithChildren[]) => {
    const companyMap = new Map<string, CompaniesModelWithChildren>();
    const parentMap = new Map<string, CompaniesModelWithChildren[]>();

    companies.forEach((company) => {
      companyMap.set(company.id, { ...company, children: [] });

      if (company.parentCompanyId) {
        if (!parentMap.has(company.parentCompanyId)) {
          parentMap.set(company.parentCompanyId, []);
        }
        parentMap.get(company.parentCompanyId)?.push(company);
      }
    });

    parentMap.forEach((children, parentId) => {
      const parentCompany = companyMap.get(parentId);
      if (parentCompany) {
        parentCompany.children = children;
      }
    });

    return Array.from(companyMap.values()).filter((company) => !company.parentCompanyId);
  };

  const orderAlphabeticalCompaniesByName = (companies: CompaniesModelWithChildren[]) => {
    return [...companies].sort((company1, company2) =>
      company1.name.toLowerCase().localeCompare(company2.name.toLowerCase())
    );
  };

  const orderAlphabeticalCompaniesList = useMemo(() => {
    const companiesList: CompaniesModelWithChildren[] = getCompaniesWithChildren(
      user?.companies || []
    );

    return orderAlphabeticalCompaniesByName(companiesList);
  }, [user?.companies]);

  const TreeItem = (company: CompaniesModelWithChildren, styles?: StyleProp<ViewStyle>) => (
    <SwitchCard
      key={company.id}
      data={company}
      type="company"
      handleSwitchProfile={() => handleSwitchProfile(company, 'company')}
      containerStyle={styles}
    />
  );

  const renderCompanies = useCallback(() => {
    if (loading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color={themes.colors.primary} />
        </View>
      );
    }

    if (!user?.companies?.length) {
      return (
        <View style={styles.noCompany}>
          <Text style={styles.noCompanyText}>{translate('switchCompany.noCompanies')}</Text>
        </View>
      );
    }

    return (
      <View>
        {orderAlphabeticalCompaniesList.map((company) => {
          const orderAlphabeticalCompaniesChilndrenList = orderAlphabeticalCompaniesByName(
            company?.children
          );
          return (
            <View key={company.id}>
              {TreeItem(company)}
              <>
                {!!company.children?.length &&
                  orderAlphabeticalCompaniesChilndrenList.map((companyChildren) => (
                    <View key={companyChildren.id} style={styles.treeChildren}>
                      <View style={styles.line} />
                      {TreeItem(companyChildren, { width: '100%' })}
                    </View>
                  ))}
              </>

              {!!company.children?.length && <Divider />}
            </View>
          );
        })}
      </View>
    );
  }, [user.companies]);

  return (
    <>
      <Modal
        isVisible={visible}
        style={styles.modal}
        onBackdropPress={() => setVisible(false)}
        onBackButtonPress={() => setVisible(false)}
      >
        <View style={{ flex: 1 }}>
          <View style={styles.modalTitle}>
            <View style={styles.modalTitleView}>
              <Feather name="x" style={[styles.modalIcon, { opacity: 0 }]} />
              <Text style={styles.modalTitleText}>{translate('general.switchProfile')}</Text>
              <TouchableOpacity
                onPress={() => setVisible(false)}
                hitSlop={{ top: 40, left: 40, bottom: 40, right: 40 }}
              >
                <Feather name="x" style={styles.modalIcon} />
              </TouchableOpacity>
            </View>
            <Divider />
          </View>

          <ScrollView contentContainerStyle={{ paddingBottom: 50 }}>
            <View>
              <Text style={styles.headerTitle}>{translate('switchCompany.visitorProfile')}</Text>

              <SwitchCard
                key={user.id}
                data={user}
                type="user"
                handleSwitchProfile={() => handleSwitchProfile(user, 'user')}
              />

              <Text style={[styles.headerTitle, { marginTop: 24 }]}>
                {translate('switchCompany.hostProfile')}
              </Text>

              {renderCompanies()}
            </View>
          </ScrollView>
        </View>
      </Modal>

      <TouchableOpacity style={buttonStyles} delayPressIn={50} onPress={() => setVisible(true)}>
        {content}
      </TouchableOpacity>
    </>
  );
}

const styles = StyleSheet.create({
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
    marginTop: 130,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#fcfcfc',
  },
  modalTitleView: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  modalTitle: {
    marginTop: 24,
  },
  modalTitleText: {
    fontFamily: fontFamily.medium,
    fontSize: 20,
  },
  modalIcon: {
    fontSize: 22,
    padding: 8,
    color: themes.colors.darkQuaternary,
  },
  headerTitle: {
    fontFamily: fontFamily.medium,
    fontSize: 18,
    paddingBottom: 18,
    paddingHorizontal: 24,
    color: themes.colors.darkPrimary,
  },
  noCompany: {
    paddingHorizontal: 24,
  },
  noCompanyText: {
    fontFamily: fontFamily.medium,
    fontSize: 14,
    color: themes.colors.darkQuaternary,
  },
  loadingContainer: {
    height: 150,
    alignItems: 'center',
    justifyContent: 'center',
  },
  treeChildren: {
    marginLeft: 32,
    flexDirection: 'row',
  },
  line: {
    width: 16,
    height: 16,
    borderLeftWidth: 2,
    borderLeftColor: transparentize(0.7, colors.dark1),
    borderBottomWidth: 2,
    borderBottomColor: transparentize(0.7, colors.dark1),
  },
});
