import { Feather } from '@expo/vector-icons';
import { HostsModel } from '@w3lcome/types';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { hostsApi } from '_/services/api';
import { lighten } from 'polished';
import React, { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TextInput, FlatList, Image, StyleSheet } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { RectButton } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';

import EmailTag from './EmailTag';
import Text from '../Text';

interface EmailTagInputProps {
  removeEmailTag: (email: string) => void;
  onSubmitEditing: (email: string) => void;
  emailsArray: string[];
  isHostList?: boolean;
  email: string;
  onChangeEmail: (text: string) => void;
}

export default function EmailTagInput({
  emailsArray,
  removeEmailTag,
  onSubmitEditing,
  email,
  onChangeEmail,
  isHostList = false,
}: EmailTagInputProps) {
    const textRef = useRef<TextInput>(null);
    const userEmail = useSelector((state: any) => state.user.email);
    const { t: translate } = useTranslation();

    const [searchHosts, setSearchHosts] = useState<HostsModel[]>([]);
    const customization = useSelector((state: any) => state.company?.customization);

    async function searchApiHost(search: string) {
      onChangeEmail(search);

      if (!customization.canViewHostEmailDuringInvite) {
          return;
      }

      if (search && isHostList) {
        try {
          const data = await hostsApi.getHosts({
          params: {
              $select: ['name', 'id', 'pictureUrl', 'email', 'position'],
              'email[$iLike]': `%${search}%`,
              'email[$ne]': userEmail,
              'email[$nin]': emailsArray,
              $limit: 5,
          },
          });

          setSearchHosts(data);
        } catch (error) {
            showMessage({
            message: translate('emailTag.searchHostError'),
            type: 'danger',
          });
        }
    } else {
      setSearchHosts([]);
    }
    }

    const List = useCallback(() => {
      if (searchHosts.length <= 0) {
          return null;
      }

      return (
        <FlatList
          data={searchHosts}
          keyExtractor={(item) => item.id}
          style={styles.list}
          renderItem={({ item }) => {
          return (
            <RectButton
              onPress={() => {
                onSubmitEditing(item.email);
                setSearchHosts([]);
                onChangeEmail('');
                
                textRef.current?.focus();
              }}
              >
              <View style={styles.autocompleteButton}>
                {item.pictureUrl ? (
                <Image style={styles.avatar} source={{ uri: item.pictureUrl }} />
                ) : (
                <View style={styles.avatar}>
                    <Feather name="user" style={styles.icon} />
                </View>
                )}

                <View style={styles.autocompleteTextContainer}>
                <Text style={styles.autocompleteText}>{item.email}</Text>
                <Text style={styles.autocompleteSmall}>{item.name}</Text>
                </View>
            </View>
            </RectButton>
        );
        }}
        scrollEnabled={false}
        />
        );
    }, [searchHosts]);

  return (
    <View style={styles.container}>
      <View style={styles.emailsContainer}>
        {emailsArray.map((etag) => (
          <EmailTag key={etag} email={etag} removeTag={removeEmailTag} />
        ))}
      </View>
      <View>
        {isHostList && <List />}

        <View style={styles.inputContainer}>
          <TextInput
            placeholder={translate('editMeeting.emailPlaceholder')}
            ref={textRef}
            autoCapitalize="none"
            keyboardType="email-address"
            value={email}
            autoCorrect={false}
            style={styles.formInput}
            onChangeText={(text)=> searchApiHost(text)}
            onSubmitEditing={() => {
                onChangeEmail('');
                setSearchHosts([]);
                onSubmitEditing(email);
            }}
          />
          <Feather name="mail" style={styles.icon} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  emailsContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  inputContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    borderColor: themes.colors.lightSecondary,
    justifyContent: 'space-between',
    marginVertical: 12,
    borderBottomWidth: 1,
  },
  formInput: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 15,
    paddingVertical: 8,
  },
  avatar: {
    width: 42,
    height: 42,
    borderRadius: 100,
    backgroundColor: themes.colors.graySecondary,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
  icon: {
    fontSize: 15,
    color: themes.colors.lightPrimary,
  },
  autocompleteButton: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    borderColor: themes.colors.lightSecondary,
    borderBottomWidth: 1,
  },
  autocompleteTextContainer: {
    flexDirection: 'column',
    width: '80%',
  },
  autocompleteText: {
    color: themes.colors.darkSecondary,
    fontSize: 14,
    marginBottom: 4,
  },
  autocompleteSmall: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    fontSize: 12,
    color: themes.colors.graySecondary,
  },
  list: {
    backgroundColor: lighten(0.2, themes.colors.lightPrimary),
    left: 0,
    position: 'absolute',
    right: 0,
    bottom: 48,
    borderColor: themes.colors.lightSecondary,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
});
