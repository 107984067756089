import { MeetingsModel } from '@w3lcome/types';
import logSentryException from '_/helpers/logSentryException';
import { meetingsApi } from '_/services/api';
import { AxiosRequestConfig } from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useUser } from './UserProvider';

interface MeetingsData {
  meetings: MeetingsModel[];
  getMeetings(params: any, config?: AxiosRequestConfig): void;
  loadMoreMeetings(params: any, config?: AxiosRequestConfig): void;
  meetingsState: MeetingsState;
}

export interface MeetingsState {
  total: number;
  limit: number;
  skip: number;
}

const MeetingsContext = createContext<MeetingsData>({} as MeetingsData);

type MeetingsType = {
  children: React.ReactNode;
};

export const MeetingsProvider: React.FC<MeetingsType> = ({ children }) => {
  const [meetings, setMeetings] = useState<MeetingsModel[]>([]);
  const [meetingsState, setMeetingsState] = useState<MeetingsState>({
    total: 0,
    skip: 0,
    limit: 0,
  });

  const companyId = useSelector((state: any) => state.company.id);

  const host = useSelector((state: any) => state.host);

  const { feathersApp } = useUser();

  useEffect(() => {
    getMeetings({ '$sort[start]': -1 });
  }, [host.id]);

  useEffect(() => {
    function addCreatedMeeting(data: MeetingsModel) {
      if (
        companyId === data.companyId &&
        (host.id === data.hostId || data.coHosts.includes(host.email))
      ) {
        setMeetings((prevMeetings) => {
          return [...prevMeetings, data].sort(
            (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
          );
        });

        setMeetingsState({ ...meetingsState, total: meetingsState.total + 1 });
      }
    }

    function updateMeeting(data: MeetingsModel) {
      if (
        companyId === data.companyId &&
        (host.id === data.hostId || data.coHosts.includes(host.email))
      ) {
        setMeetings((prevMeetings) =>
          prevMeetings.map((meeting) => (meeting.id === data.id ? data : meeting))
        );
      }
    }

    function removeMeeting(data: MeetingsModel) {
      if (
        companyId === data.companyId &&
        (host.id === data.hostId || data.coHosts.includes(host.email))
      ) {
        setMeetings((prevMeetings) => prevMeetings.filter((meeting) => meeting.id !== data.id));
        setMeetingsState({ ...meetingsState, total: meetingsState.total - 1 });
      }
    }

    feathersApp?.service('meetings').on('created', addCreatedMeeting);
    feathersApp?.service('meetings').on('patched', updateMeeting);
    feathersApp?.service('meetings').on('removed', removeMeeting);

    return () => {
      feathersApp?.service('meetings').off('created', addCreatedMeeting);
      feathersApp?.service('meetings').off('patched', updateMeeting);
      feathersApp?.service('meetings').off('removed', removeMeeting);
    };
  }, [feathersApp, companyId, host, meetingsState]);

  async function getMeetings(params: any, config?: AxiosRequestConfig) {
    try {
      const { data, total, skip, limit } = await meetingsApi.getMeetings(
        { ...params, companyId },
        config
      );

      setMeetings(data);
      setMeetingsState({ total, skip, limit });
    } catch (error) {
      logSentryException({
        error,
        file: 'MeetingsProvider.tsx',
        message: 'Error at getMeetings function',
      });
    }
  }

  async function loadMoreMeetings(params: any, config?: AxiosRequestConfig) {
    try {
      const { data, total, skip, limit } = await meetingsApi.getMeetings(params, config);
      setMeetings([...meetings, ...data]);
      setMeetingsState({ total, skip, limit });
    } catch (error) {
      logSentryException({
        error,
        file: 'MeetingsProvider.tsx',
        message: 'Error at loadMoreMeetings function',
      });
    }
  }

  return (
    <MeetingsContext.Provider value={{ meetings, getMeetings, meetingsState, loadMoreMeetings }}>
      {children}
    </MeetingsContext.Provider>
  );
};

export function useMeetingsContext() {
  const context = useContext(MeetingsContext);

  if (!context) {
    throw new Error('useMeetings must be used within a MeetingsProvider');
  }

  return context;
}
