import 'react-native-gesture-handler';
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import Constants from 'expo-constants';
import React, { useRef } from 'react';
import { LogBox } from 'react-native';
import * as Sentry from '@sentry/react-native';

import AppContextApi from './AppContextApi';
import { appConfig } from './src/config/app';
import { setNavigator } from '_/services/navigation';

LogBox.ignoreLogs([
  'Setting a timer for a long period of time',
  'Possible Unhandled Promise Rejection',
]);

if (appConfig.sentryDNS) {
  Sentry.init({
    dsn: appConfig.sentryDNS,
    debug: false,
    release: Constants.expoConfig?.version,
  });
}

export interface AppProps {
  skipLoadingScreen?: boolean;
}

export default function App({ skipLoadingScreen = false }: AppProps) {
  const navigationRef = useRef<NavigationContainerRef<any>>(null);
  setNavigator(navigationRef);

  return (
    <NavigationContainer ref={navigationRef}>
      <AppContextApi skipLoadingScreen={skipLoadingScreen} />
    </NavigationContainer>
  );
}
