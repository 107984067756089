import produce from 'immer';
import { CameraType } from 'expo-camera/build/legacy/Camera.types';

const INITIAL_STATE = {
  isSearchOpen: false,
  searchText: '',
  cameraType: CameraType.front,
  qrCodeCameraType: CameraType.front,
};

export default function settings(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@settings/TOGGLE_SEARCH': {
        draft.isSearchOpen = !state.isSearchOpen;
        break;
      }
      case '@settings/CHANGE_SEARCH_TEXT': {
        draft.searchText = action.payload;
        break;
      }
      case '@settings/CHANGE_CAMERA_TYPE': {
        draft.cameraType = action.payload;
        break;
      }
      case '@settings/CHANGE_QRCODE_CAMERA_TYPE': {
        draft.qrCodeCameraType = action.payload;
        break;
      }
      default:
    }
  });
}
