import { VisitAuthorizationStatusType, VisitAuthorizationVia, VisitsModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import api from './base';
import { GetListType } from './types/GetListType';
import { ReqQueryType } from './types/ReqQuery';

interface AuthorizationPayload {
  visitId: string;
  authorizationVia?: VisitAuthorizationVia;
  authorizationStatus?: VisitAuthorizationStatusType;
  visitorNoteDenied?: string | null;
}

class VisitsApi {
  private cancelPath = 'visit/cancel';
  private delayPath = '/visits/delay';

  public getVisit = async (visitId: string, params?: any) => {
    const { data } = await api.get<VisitsModel>(`/visits/${visitId}`, params);
    return data;
  };

  public getVisits = async (params: ReqQueryType<VisitsModel>, config?: AxiosRequestConfig) => {
    const { data } = await api.get<GetListType<VisitsModel>>(`/visits`, {
      ...config,
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  };

  public authorizeOrDenyVisit = async (values: AuthorizationPayload) => {
    const { data } = await api.post(`/authorizations`, values);
    return data;
  };

  public checkinVisit = async (visit: VisitsModel) => {
    const { data } = await api.post(`/checkin`, { visit });
    return data;
  };

  public update = async (visitId: string, params: any) => {
    const { data } = await api.patch(`/visits/${visitId}`, params);
    return data;
  };

  cancel = async (id: string, companyId: string) => {
    const { data } = await api.post(
      `${this.cancelPath}`,
      { id },
      {
        params: {
          companyId,
        },
      }
    );
    return data;
  };

  delayVisit = async (
    visit: VisitsModel,
    minutesToDelayVisit: number,
    delayVisitReason?: string
  ) => {
    const { id, hostId, companyId } = visit;

    const { data } = await api.post<VisitsModel>(this.delayPath, {
      id,
      minutesToDelayVisit,
      delayVisitReason,
      companyId,
      hostId,
    });

    return data;
  };
}

export default new VisitsApi();
